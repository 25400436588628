header {
    position: relative;
    //z-index: 11;
}

//#page-preloader{
//    z-index: 10;
//}
.tab-content .item .product-miniature .product-description .product-buttons .formAddToCart .add-to-cart:hover {
    background-color: red;
    color: white;
    border-color: red;
}

.paired-products-block {
    .item {
        margin-bottom: 22px;
    }

    .title-product {
        margin-bottom: 16px;
    }

    .product-miniature {
        height: 100%;
        display: flex;
        border: 1px solid #dee2e6 !important;
        margin-bottom: 10px;
        border-radius: 5px;
        //flex-direction: column;
        .product-description {
            padding: 21px 17px 16px !important;

            .product-groups .product-group-price .price {
                text-align: right;
                color: red !important;
            }

            .product-buttons {
                top: unset !important;
                bottom: 31px !important;
                justify-content: flex-end;

                .formAddToCart {

                }
            }

            .paired-prod-info {
                justify-content: space-between;
                display: flex;

                .discount-info {
                    display: none;
                }
            }
        }
    }

    .thumbnail-container {
        display: flex;
        justify-content: center;
        flex-grow: 2;
        border: none !important;

        .links-container {
            align-self: center;
            display: inline-flex !important;
            flex-wrap: wrap;
            padding: 10px;
            min-height: 150px;
            justify-content: center;
            align-items: center;

            img {
                max-width: 90px;
                max-height: 90px;
                margin: 4px;
            }
        }

    }

    .tab-content .item .product-miniature .product-description .product-buttons .add-to-cart-group {
        color: black;
        height: 38px;
        text-align: center;
        line-height: 38px;
        border-radius: 3px;
        background: #fff;
        border: 1px solid #ddd;
        display: inline-block;
        margin-right: 10px;
        width: 152px;
        justify-content: center;

        i {
            margin: inherit !important;
            align-self: center;
        }
    }
}

.menu-banner .verticalmenu .menu li .dropdown-menu .menu-items .item .menu-content .tags .title > a {
    //.menu-banner .verticalmenu .menu li .dropdown-submenu .menu-items .item .menu-content .tags .title{
    padding: 0;
}

.tab-content .item .product-miniature .product-description .product-buttons a {
    display: inline-flex !important;

    i.fa {
        margin: auto !important;
    }

}

body.product-details .content .main-product-detail .product-detail .page-content .product-tab li {
    max-width: 30%;
}

.pagination {
    li {
        &:not(.disabled):hover {
            cursor: pointer !important;
            //background: #c4c4c4 !important;
        }

        &.disabled {
            &:hover {
                //background: #c4c4c4 !important;
                background:transparent !important;
            }

            a {
                cursor: default !important;
            }

            span {
                cursor: default !important;
            }
        }

        a {
            cursor: pointer !important;
        }

        span {
            cursor: default !important;
        }

    }
}

#confirmBox h1 {
    background: #bdb8b8 !important;
}

.user-order-credentials {
    .form-control {
        margin-bottom: 12px;
        border-radius: 0;
        font-size: 12px;
    }
}

.langs-header-top {
    #dropdown-langs {
        background-color: #ffffff !important;
        color: black;
        border: none;
        border-radius: 0;

    }

    .dropdown-menu {
        img {
            width: 30px;
            margin-right: 10px;
            border: 1px solid #eeeeee;
        }

        .dropdown-item {
            display: flex;
            align-items: center;
            font-size: 14px;
        }
    }
}

#block_myaccount_infos {
    #dropdown-user-account {
        background-color: #ffffff !important;
        color: black;
        border: none;
        border-radius: 0;

        i {
            cursor: pointer;
        }
    }

    line-height: 2;

    .dropdown-menu {
        .dropdown-item {
            font-size: 14px;
        }
    }
}

.paginate-select {
    max-width: 96px;
    padding: 8px 0 8px 8px;
    //margin-top: 7px;
    position: relative;
    top: 2px;
    border: 1px solid #ececec;
    border-radius: 4px;
    line-height: 1;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
    margin-left: 10px;

}

.js-qv-mask.mask.tab-content {
    width: 100%;
}

.parsley-errors-list {
    margin-bottom: 0;

    li {
        color: red;
        text-transform: none;
        font-size: 13px;
    }
}

.swal-button {
    background-color: #a9a9a9;
    border-radius: 1px;

    &:hover {
        background-color: #959292 !important;
    }
}

.swal-button--cancel {
    background-color: transparent;

    &:hover {
        background-color: #f2f2f2 !important;
    }
}

.product-line-grid-left.product-groups {
    .product-image {
        margin-bottom: 12px;
    }
}

#home5 header .header-top .block-cart .dropdown-content .cart-content {
    margin-top: -40px;
}

#make-order-paypal {
    background: url(../../images/paypal.png) no-repeat center 2px;
    //("../../../images/furnitica/product/star1.png")
    background-size: contain;
    background-position: center 2px;
}

#make-order-klarna {
    background: url(/build/images/klarna.webp?5dec920…) no-repeat 10px 14px;
    background-size: 53%;
    background-position: center 7px;
}

#make-order-transfer {
    //background: url(/build/images/klarna.webp?5dec920…) no-repeat 10px 14px;
    //background-size: 53%;
    //background-position: center 7px;
    text-indent: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    letter-spacing: -1px;
    font-size: 27px;
    font-style: italic;
}

.btn-payment {
    text-indent: 99999;
    border: 1px solid #909090 !important;
    min-width: 208px;
    display: inline-flex;
    width: 150px;
    height: 50px;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder, select::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #b2afaf !important;
    font-weight: normal !important;
}

input:-moz-placeholder, textarea:-moz-placeholder, select:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #b2afaf !important;
    opacity: 1;
    font-weight: normal !important;

}

input::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #b2afaf !important;
    opacity: 1;
    font-weight: normal !important;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder, select:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #b2afaf !important;
    font-weight: normal !important;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder, select::-ms-input-placeholder { /* Microsoft Edge */
    color: #b2afaf !important;
    font-weight: normal !important;
}

input::placeholder, textarea::placeholder, select::placeholder { /* Most modern browsers support this now. */
    color: #b2afaf !important;
    font-weight: normal !important;
}

textarea::placeholder {
    /*padding-top: 4px;*/
}

.hidden {
    display: none;
}

.cart-item-not-available {
    text-decoration: line-through;
}

.profile-menu-item {
    &:hover,
    &.active {
        a {
            color: #a8abba;
        }

    }
}

.user-profile-table {
    tr {
        th {
            width: 35%;
        }
    }
}


//>[aria-sort]{
//    //background-position: right calc(0.75rem / 2) center ;
//    padding-right: 28px !important;
//    background-size: 7px !important;
//}
th.sorting:before {
    display: none !important;
}

th.sorting:after {
    content: '' !important;
    width: 9px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    background-position: center;
    top: 0 !important;
    opacity: 1 !important;
}

.sorting_disabled {
    &:before,
    &:after {
        display: none !important;
    }
}

th.sorting:after {
    //background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='26'%3E%3Cg stroke='%23C6C4D0' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 19l6 6 6-6M13 7L7 1 1 7'/%3E%3C/g%3E%3C/svg%3E") !important;
    background-image: url(../../images/icons/table-sort.svg) !important;
}

th.sorting.sorting_desc:after {
    //background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='26'%3E%3Cg stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 19l6 6 6-6' stroke='%235E5873'/%3E%3Cpath d='M13 7L7 1 1 7' stroke='%23C6C4D0'/%3E%3C/g%3E%3C/svg%3E") !important;
    background-image: url(../../images/icons/table-sort-desc.svg) !important;
}

th.sorting.sorting_asc:after {
    //background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='26'%3E%3Cg stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 19l6 6 6-6' stroke='%23C6C4D0'/%3E%3Cpath d='M13 7L7 1 1 7' stroke='%235E5873'/%3E%3C/g%3E%3C/svg%3E") !important;
    background-image: url(../../images/icons/table-sort-asc.svg) !important;
}

#datatable_wrapper {

    table {
        border-color: #f3f3f3 !important;

        td {
            height: 50px;
        }

        margin-bottom: 15px;
    }

    .paginate_button:not(.disabled) {
        width: 35px;
        height: 35px;
        display: inline-block;
        //margin-right: 5px;
        border: 1px solid #dfdfdf;
        background: #c4c4c4;
        border-radius: 4px;
        color: white !important;
        vertical-align: middle;
        font-size: 14px;
        /* float: left; */
        text-align: center;

        &.current {
            border-color: #222;
            background: #343434;
        }
    }
}

#datatable_processing {
    font-size: 0 !important;

    > div > div {
        background: #e7e7e7 !important;
    }

}

td.details-control {
    background: url(https://www.datatables.net/examples/resources/details_open.png) no-repeat center center;
    cursor: pointer;
    width: 30px;
    transition: .5s;
}

tr.shown td.details-control {
    background: url(https://www.datatables.net/examples/resources/details_close.png) no-repeat center center;
    width: 30px;
    transition: .5s;
}

.img-responsive {
    max-width: 100%;
}

/*****************************************fixed-block******************/
#conf-wrapper {
    .price, .step-header {
        color: #806a6c;
        display: block;
        //background-image: linear-gradient(to right, #f3f3f3, #f3f3f3);
        //text-align: center;
        font-weight: bold;
        font-size: 23px;
        padding: 6px 9px;
        transition: all 0.3s;
    }

    .price, .step-header {
        margin: 0 auto 4px auto;
        line-height: 26px;
    }

#preview img{
    border-radius:5px;
}
    .price, .step-header {
        margin: 0px auto 0px auto;
    }

    .left-part {
        border-right: none;
        padding-top: 0;
        padding-bottom: 0;
        width: 280px;
    }

    .parsley-errors-list li {
        font-size: 13px;
        margin-bottom: 7px;
    }

    .sizes .parsley-errors-list li {
        padding-left: 0;

    }

    .right-part {
        width: calc(100% - 280px);
        padding: 0 20px;
        //margin-top: 4px;
    }

    #fixed-block-info {
        .product-title {
            margin-bottom: 4px;
            font-size: 16px;
        }

        #conf-selected-info {
            //margin-bottom: 10px;
            margin-top: 4px;
            //padding: 10px 16px;
            //background-color: #f3f3f3;

            .price-block {
                font-size: 18px;
                /* text-align: center; */
                margin-top: 15px;
                margin-left: -10px;
                color: #ff4f61;
                font-weight: bold;
            }

            #add_to_basket {
                margin: 0;
                margin-top: 6px;
            }
            .selected-info-title {
                font-weight: bold;
                letter-spacing: 3px;
                text-transform: uppercase;
                /* background-color: #bcbcbc; */
                color: #0e0e0e;
                padding: 3px 12px 3px 0px
            }

            #selected-info {
                padding: 0 0 14px 0;

                .fa-exclamation-triangle {
                    color: red;
                }

                .row-info {
                    &:hover {
                        background-color: gainsboro;
                    }
                    border-bottom: 1px dashed #cfcfcf;
                    position: relative;
                    display: inline-block;
                    //padding: 6px 0;
                    padding: 6px 16px 6px 0px;
                    width: 100%;

                    .scrolling-icon {
                        //i{
                        padding-right: 2px;
                        justify-content: center;
                        position: absolute;
                        top: 0px;
                        right: 0;
                        width: 18px;
                        display: inline-flex;
                        height: 33px;
                        align-items: center;
                        /* margin: auto; */
                        text-align: center;
                        //}
                    }

                    .r-title {
                        display: inline-block;
                        margin-right: 2px;
                    }
                }
            }
        }
    }

    .fixed-info {
        margin-top: 4px;
        position: fixed;
        z-index: 100;
        max-height: calc(100vh - 8px);
        overflow: auto;
    }

}

.external-roll-container {
    .content-centered-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > div {
            text-align: center;
        }

        .parsley-errors-list {
            li {
                padding-left: 0 !important;
            }
        }
    }

}

.checkbox-smart input[type=radio],
.checkbox-smart input[type=checkbox] {
    display: none;
}

.checkbox-smart label, .rol-sub-type label {
    margin-bottom: 0;
    position: relative;
    cursor: pointer;
}

.checkbox-smart label {
    cursor: pointer;
    height: auto;
    box-shadow: 1px 1px #dadada;
    border: 1px solid #d6d4d4;
    width: auto;
    background: #fff;
    margin-bottom: 19px;

    &.conf-item-label {
        margin-right: 20px;
        height: 100%;
        min-height: 149px;
        width: 120px;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
        border-radius: 4px;
        img {
            border-radius: 5px 5px 0 0;
            width: 100%;
            border-bottom: 1px solid #d7d7d7;
        }

        span {
            width: 100%;
        }

        .price-label {
            text-align: center;
            background-color: #95be73;
            color: white;
        }

        .description-label {
            margin-top: auto;
            padding: 6px;
            font-size: 13px;
            text-align: center;
        }

        &.color-label {
            margin-right: 19px;

            img {
                height: 75px;
            }
        }
    }
}

.checkbox-smart label img {
    /*height: 100%;*/
    max-height: 126px;
    margin: 0 auto;
}

.checkbox-smart label:after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    content: '';
    opacity: 0;
    //border:1px solid white;
    transition: all 0.5s;
}

.rol-color label:after {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.checkbox-smart input[type=radio] + label:hover,
.checkbox-smart input[type=checkbox] + label:hover {
    //-webkit-box-shadow: 0 0 8px 1px rgba(255,140,0,.35);
    //-moz-box-shadow: 0 0 8px 1px rgba(255,140,0,.35);
    box-shadow: 0 0 2px 1px rgb(173 173 173);

}

.checkbox-smart input[type=radio]:checked + label:after, .checkbox-smart input[type=checkbox]:checked + label:after {
    //outline: #e5cbcb solid 3px;
    outline: #46c42f solid 3px;
    /* border: 1px solid #ff8c00; */
    opacity: 1;
    border-radius: 5px;
}

.checkbox-smart input[type=radio] + label:before, .checkbox-smart input[type=checkbox] + label:before {
    position: absolute;
    display: inline-block;
    top: -8px;
    border-radius: 9px;
    right: -12px;
    text-align: center;
    border: 1px solid #85dc8a;
    line-height: 24px;
    height: 25px;
    width: 25px;
    z-index: 2;
    content: "✔";
    color: #ffffff;
    background-color: #98d9a6;
    opacity: 0;
    /* border: 1px solid white; */
    transition: all 0.5s;
}

.checkbox-smart input[type=radio]:checked + label:before, .checkbox-smart input[type=checkbox]:checked + label:before {
    opacity: 1;
}

.checkbox-smart input[type=checkbox][name^=color]:checked + label:after,
.checkbox-smart input[type=radio][name^=color]:checked + label:after {
    outline: #e5cbcb solid 3px;
    opacity: 1;
}

.configurator-title {
    border-bottom: 2px dashed #cfcfcf;
    margin-top: 0px;
    margin-bottom: 20px;
    color: #0d0d0d;
    font-weight: bold;
    letter-spacing: 1px;
    background-color: #ffffff;
    text-align: left;
    width: 100%;
    padding: 7px 15px 7px 0;
}

.conf-sector {
    .hide-errors + .parsley-errors-list{

            display: none !important;

    }
    display: flex;
    &.hidden{
        display: none;
    }
    flex-wrap: wrap;
    padding: 0px 0 0px 0;

    .conf-sector-content {
        padding: 10px 0 10px 0;
        display: flex;
        flex-wrap: wrap;


    }

    .colors-tab.active {
        display: flex;
        flex-wrap: wrap;
    }

    input,
    .input-group-text,
    .input-group-append {
        border-radius: 0;
    }

    .typed-labels {
        input:checked + label {
            background-color: #f7f7f7;
            box-shadow: inset 0px 0px 4px rgb(222 222 222);

            span.price-label {
                opacity: 0;
            }
        }

        label:not(.color-label) {
            justify-content: space-between;
            min-height: 40px;
            text-align: left;
            /* padding: 7px 10px; */
            white-space: nowrap;
            display: flex;
            align-items: center;
            padding-left: 8px;
            min-width: 300px;
            box-shadow: none;

            span {
                justify-content: center;
                display: inline-flex;
                min-width: 64px;
                background-color: #95be73;
                color: white;
                margin-left: 9px;
                align-self: stretch;
                align-items: center;
                //padding: 4px;
                padding: 4px 7px;
                font-size: 11px;
            }
        }

    }

    .addon-colors-container {
        display: flex;
        width: 100%;
        //column-gap: 13px;
        margin-bottom: 1rem;
        margin-top: 1rem;

        > div {
            display: flex;
            flex-direction: column;

            label {
                min-height: 110px;
                margin-right: 0;

                img {
                    height: 50px !important;
                }
            }
        }
    }
}
.request-errors-container{
    margin-bottom: 0;
}
#cart{
    .description-container{
        //position: relative;
        display:flex;
        flex-wrap:wrap;
    }
    .expander-container{
        position: relative;
        gap:5px;
    }
    .expander-element{
        position: absolute;
        bottom: 9px;
        right: 3px;cursor:pointer;
    }
    .half-opened{
        height: 74px;
        overflow: hidden;
        //display: -webkit-box;
        //-webkit-line-clamp: 1;
        //-webkit-box-orient: vertical;
        //text-overflow: ellipsis;
        //> a{
        //    display: block;
        //}
    }
}

.site--page{
    #main {
        margin: 5rem 0;
    }
    .page-title{
        margin-bottom: 30px;
    }
    .page-content-container{
        margin-bottom: 30px;
    }
    .title-icon{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        //margin-right: 12px;
        width: 30px;
        height: 30px;
        font-size: 1rem;
        line-height: 30px;
        color: #fff;
        background: #afafaf;
        border-radius: 50%;
        margin-right: 15px;
        text-align: center;
        float: left;
    }
    &.contact--page{
        #form-contact .content{
            padding-left: 2.7rem;
            max-width: 600px;
        }

    }
}
#shop-cart-coupon-block{
    input{
        width: calc(100% - 45px);
        //border-top-right-radius:5px;
        //border-bottom-right-radius:5px;
    }

    input[type=checkbox]:checked + label:after{
        content: "\f00d" !important;
    }

    #apply-coupon{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        display: inline-flex;
        align-items: center;
        margin-left: auto;
        /* height: 100%; */
        align-self: stretch;
        margin-bottom: 0;
        &:after{
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\f00c";
        }
    }
}
.product-search-list{
    input{
        border: 1px solid #ececec;
        font-size: 12px;
    }

}
.sort-by-row{
    align-items: flex-end;
    justify-content: flex-end;
}
.filter-color-checkbox{
    display: none;
    &:checked + label{
    outline: #dadada solid 5px;
    }
}

.linear-pagination{
ul{

    li{
        width: 24px;
        margin-right: 3px;
        text-align: center;
        a{
            font-size: 15px !important;
        }
        &.active{
            background-color: gainsboro;
            border-radius: 3px;
            color: white !important;
        }
        &:not(.disabled):hover {
            //cursor: pointer !important;
            background: #c4c4c4 !important;
           a{
               color: white !important;
           }
            border-radius: 3px;
        }
    }
}
}
.acceptation-link{
    color:#8585e3;
}
.product-title{
    font-size: 1.6rem;
    font-weight: 600;
}
#conf-subtype-title{
    font-weight:bold;
    text-align: right;
}


.paginate_button.next{
    font-size: 0 !important;
    position: relative;
    &:after{
        position: absolute;
        left: 0;
        top: 0;
        align-items: center;
        justify-content: center;
        font-size: 12px !important;
        display: flex;
        height: 100%;
        width: 100%;
        content: ">";
    }
    &.disabled{
        width: 35px;
        height: 35px;
        background-color: #f4f4f4;
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        color: #0b0b0b !important;
        text-align: center;
    }
}
//:not(.disabled)
.paginate_button.previous{
    font-size: 0 !important;
    position: relative;
    &:after{
        position: absolute;
        left: 0;
        top: 0;
        align-items: center;
        justify-content: center;
        font-size: 12px !important;
        display: flex;
        height: 100%;
        width: 100%;
        content: "<";
    }
    &.disabled{
        width: 35px;
        height: 35px;
        background-color: #f4f4f4;
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        color: #0b0b0b !important;
        text-align: center;
    }
}

table.dataTable{
    font-size: 14px;
    table{
        font-size: 13px;
        td{
            height: 30px;
        }
    }
}
#logo, #footer-logo{
    img{
        max-height: 87px;
        max-width: 220px;
    }
}
.mobile .mobile-logo img{
    max-height: 32px;
}


//toggle btns
$toggle-indicator-size: 16px; // changing this number will resize the whole toggle
$track-height: $toggle-indicator-size + 6;
$track-width: $toggle-indicator-size * 2.8;
$highContrastModeSupport: solid 2px transparent;
/*

The following vars come from my theme.
You'll need to replace with your own color values.

- "$light"
- "$mid"
- "$dark"

*/
$mid:#9973ff;
$dark:#3c337a;
$light:white;
$speed:300ms;
$track-border: $mid;
$track-background: $light;
$focus-ring: 0px 0px 0px 1px $dark;

// Toggle specific styles
.toggle {
    align-items: center;
    border-radius: 100px;
    display: flex;
    font-weight: 700;
    margin-bottom: 16px;

    &:last-of-type {
        margin: 0;
    }
}

// Since we can't style the checkbox directly, we "hide" it so we can draw the toggle.
.toggle__input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    // This style sets the focus ring. The ":not([disabled])" prevents the focus ring from creating a flash when the element is clicked.
    &:not([disabled]):active + .toggle-track,
    &:not([disabled]):focus + .toggle-track {
        border: 1px solid transparent;
        box-shadow: $focus-ring;
    }

    &:disabled + .toggle-track {
        cursor: not-allowed;
        opacity: 0.7;
        background-color: #d8d8d8;
        border: 1px solid #f9f9f9;
        .toggle-indicator{
            background: #838383 !important;
        }
    }
}

.toggle-track {
    background: $track-background;
    border: 1px solid $track-border;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    height: $track-height;
    margin-right: 12px;
    position: relative;
    width: $track-width;
}

.toggle-indicator {
    align-items: center;
    background: $dark;
    border-radius: $toggle-indicator-size;
    bottom: 2px;
    display: flex;
    height: $toggle-indicator-size;
    justify-content: center;
    left: 2px;
    outline: $highContrastModeSupport;
    position: absolute;
    transition: $speed;
    width: $toggle-indicator-size;
}

// The check mark is optional
.check-mark {
    fill: #fff;
    height: $toggle-indicator-size - 4;
    width: $toggle-indicator-size - 4;
    opacity: 0;
    transition: opacity $speed ease-in-out;
}

.toggle__input:checked + .toggle-track .toggle-indicator {
    background: $dark;
    transform: translateX($track-width - $track-height);

    .check-mark {
        opacity: 1;
        transition: opacity $speed ease-in-out;
    }
}

@media screen and (-ms-high-contrast: active) {
    .toggle-track {
        border-radius: 0;
    }
}
.mobile-top-menu .tiva-verticalmenu .menu li span.arrow{
position: absolute;
right: 0;
top: 10px;
width: 20px;
height: 20px;
text-align: center;
line-height: 20px;
color: #fff;
border-radius: 2px;
background: #cfcfcf;
color: #fff;
z-index: 9;
cursor: pointer;
}
.checkout-personal-step {
    .btn-payment{
        &:hover{
            background-color:#fbfbfb !important;
        }
    }
    input[name=payment_type]:checked + label{
        background-color:#fbfbfb !important;
        position: relative;
        &:after{
            position: absolute;
            display: inline-block;
            top: -8px;
            right: -12px;
            text-align: center;
            line-height: 24px;
            height: 25px;
            width: 25px;
            z-index: 2;
            content: "✔";
            text-indent: 0;
            color: rgb(255, 255, 255);
            background-color: rgb(152, 217, 166);
            //opacity: 0;
            border-radius: 9px;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(133, 220, 138);
            border-image: initial;
            transition: all 0.5s ease 0s;
        }
    }
}
.btn-rounded{
    border-radius: 50px;
}
.header-mobile #dropdown-mobile-langs{
    background-color: transparent;
    border: none;
    margin-right: -20px;
    //margin-top: -10px;
    + .dropdown-menu{
        max-width: 80px;
        min-width: unset;
        img{
            max-width: 30px;
        }
        &.show{
            position: absolute;
            will-change: transform;
            top: 0px;
            left: 0px;
            transform: translate3d(-3px, 28px, 0px);
        }
        .dropdown-item{
            padding: 3px 7px;
        }
    }
}

div.swal2-container button.swal2-styled.swal2-confirm{
    background-color: #a9a9a9;
    border-radius: 1px;
    order: 2;
}
div.swal2-container button.swal2-styled.swal2-cancel{
    background-color: transparent;
    border-radius: 1px;
    color: #a9a9a9;
}
div.swal2-container button{
    padding: 0.425em 0.9em;
}
div.swal2-container h2.swal2-title{
    font-size: 20px;
    margin-bottom: 13px;
}
div.swal2-icon.swal2-info{
    border-color: #c9dae1;
    width: 4.5em;
    height: 4.5em;
    margin: 1.8em auto 0.6em;
}
div.swal2-container .swal2-html-container{
    font-size: 16px;;
}
div.swal2-icon.swal2-info .swal2-icon-content{
    color: #c9dae1;
}
.inner-footer .tiva-html  .policy-content {
    background: #fff;
    padding: 42px 0;
    border: 1px solid #e0e6ec;
    box-shadow: 8px 7px 0 0 #e7ebef;
    width: 100%;
    line-height: 2;
    position: relative;
    font-size: 12px;
    color: #222;
    height:100%;
}
.inner-footer .tiva-html .policy-content img {
    float: inherit;
    height: 38px;
    margin-bottom: 1rem;
    width: 40px;
    align-items: center;
    margin-top: 8px;
    margin-right: 27px;
}
.inner-footer .tiva-html .policy-content .policy-name {
    text-align: initial;
    font-size: 16px;
    display: inline;
    margin: 0 !important;
    padding: 0;
    text-transform: uppercase;
    color: #222;
    font-weight: 600;
}
.inner-footer  .tiva-html .policy-content .policy-des {
    color: #666;
    font-weight: 400;
}
