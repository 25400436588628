.cursor-pointer{
    cursor:pointer;
}
.select2{
    &.select2-hidden-accessible{
    + .select2-container{
        width:100% !important;
    }
    }

}

.progress-loader{
    position: relative;
    height: 7px;
    .progress-bar{
        height:100%;
        position: absolute;
        animation-name: progress-loading;
        animation-duration: 1200ms;
        //animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        //transition: left 0.3s;
        animation-timing-function: linear;
    }

}
@keyframes progress-loading {
    0% {
        left: 0;
        width:50%
    }
    50% {
        left: 50%;
        width: 50%;
    }
    100% {
        left: 100%;
        width: 50%;
    }
}

//@keyframes switch-reverse {
//    0% {
//        left: 22px;
//        width: 19px;
//    }
//    60% {
//        left: 22px;
//        width: 24px;
//    }
//    100% {
//        left: -3px;
//    }
//}

.rating {
    .star-content{
        .star{
            color: #ddd;
            float: right;
            margin: -3px;
            overflow: hidden;
            margin-top: 2px;
        }
        .star:before {
            margin: 4px;
            font-size: 12px;
            font-family: FontAwesome;
            display: inline-block;
            content: "\f005";
            //cursor: pointer;


        }
        .star.active {
            color: #f7bc3d;
        }
        .star.half:before{
            content: "\f123";
        }
    }

}


