@media (min-width: 1200px) {

}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
    #form-basket{
        .cart-grid-left{
            order:2;

        }
        .cart-grid-right{
            margin-bottom: 40px;
            .cart-summary{
                margin-top: 0;
            }
        }
    }
    #conf-wrapper{
        .left-part{
            min-width: 100%;
            #conf-subtype-title{
                text-align: left;
                margin-bottom: 15px;
            }
            .product-title{
                font-size: 20px;
            }
        }
        .right-part{
            min-width: 100%;
            padding: 0;
        }
    }
}

@media (max-width: 768px) {


}
@media (max-width: 480px) {
    #form-basket{
        .step-title{
            margin-bottom: 18px;
            font-size: 1rem;
        }
         .check-info .content{
           padding-left: 0;
        }
        .checkout-personal-step .step-title .step-number {
            width: 24px;
            height: 24px;
            font-size: 0.8rem;
            line-height: 26px;
        }
        .btn.btn-primary, .product-cart .btn.btn-primary{
            margin: 0.5rem 0;
        }
    }
    .back-to-top a{
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 18px;
        i{
            line-height: 32px;
        }
    }
#conf-wrapper{
    .right-part{
        .checkbox-smart label.conf-item-label{
            min-height: 130px;
            width: 90px;
            margin-right: 6px;
            //margin-bottom: 6px;
        }
        .checkbox-smart label.conf-item-label .description-label{
            font-size:11px;
        }
        .checkbox-smart label.conf-item-label.color-label {
            margin-right: 6px;
        }
        .conf-sector .typed-labels label:not(.color-label){
            min-height: 33px;
        }
    }
    .input-group-text{
        font-size: 0.75rem;
    }
    .form-control {

        padding: 0.275rem 0.75rem;
        font-size: 0.8rem;
    }
}
}

@media (max-width: 400px) {
.product-container {.sort-by-row{
    flex-wrap:wrap;
    .products-sort-order{
        order:2;
        select{
            width: 100%;
            margin-left: 0 !important;
        }
    }
    .product-search-list{
        width:100%;
        margin-left: 0 !important;
    }
}
    .js-product-list-top{
        &:before{
            display: none;
        }
        .total-products{
            >p{
                font-size: 10px !important;
                line-height:38px !important;
            }
        }
    }
}
}
